:root {
    --main-bg-color-grad: linear-gradient(to top, #672431, #FB6059);
    --full-opacity: rgb(0,0,0,0);
    --main-bg-color: #B23A48;
}

#main_layout {
    display: flex;
}

#main_content {
    flex-grow: 1;
    height: 100vh;
    width: calc(100% - 17%);
    position: fixed;
    left: 17%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--main-bg-color);
}

.main_inner {
    margin: 20px;
    width: calc(100% - 40px);
    height: calc(100vh - 20px);
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
}

.main_inner_content {
    flex-grow: 1;
    padding: 10px;
    overflow-y: auto;
    padding-bottom: 0px;
}

.main_inner_top_interact_header {
    color: white;
    font-size: 36px;
    margin-bottom: 20px;
}

.main_inner_top_interact {
    display: flex;
    flex-direction: column;
}

.button_date {
    background-color:rgba(0, 0, 0, 0.25);
    border: 0px;
    border-radius: 4px;
    margin-left: 10px;
    font-size:15px;
    color: white;
    width:100px;
    height:30px;

}

.button_date:hover {
    cursor: pointer;
    background-color:rgba(0, 0, 0, 0.35);

}
