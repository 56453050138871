:root {
    --main-bg-color: #B23A48;
    --main-bg-color-hover: #7b2932;
    --main-bg-color-dropdown: #99323e;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.dropdown_container {
    position: relative;
    border-radius: 4px;
    width: 150px;
}

.dropdown_list {
    overflow-y: auto;
}

.dropdown_container {
    font-size:15px;
}

.dropdown_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.25);
    color: white;
    cursor: pointer;
    border-radius: 4px;
    position: relative;
    z-index: 2;
}

.dropdown_header:hover {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 4px 4px 0 0;
}

.dropdown_list {
    position: absolute;
    top: calc(100% - 5px);
    left: 0;
    max-height: 30vh;
    width: 100%;
    padding-top: 5px;
    background-color: var(--main-bg-color-dropdown);
    color: white;
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown_item {
    padding: 8px 10px;
    cursor: pointer;
}

.dropdown_item:hover {
    background-color: var(--main-bg-color-hover);
}

.dropdown_arrow {
    margin-left: auto;
    font-size: 12px;
}
