.search_button {
    display: flex;
    color: white;
    align-items: center;
    font-size: 20px;
}

.plus_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27px;
    font-weight: bold;
    height: 27px;
    background-color: rgb(178, 53, 53);
    border-radius: 50%;
    color: white;
    padding: 5px;
}

.search_button p {
    padding: 10px 7px 10px 10px;
    font-weight: bold;
}