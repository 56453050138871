.data_container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-radius: 8px;
  color: white;
  flex: 1;
}

.bike_desc_title {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}

#modal_inner_fix > form {
    display: flex;
    flex-direction: column;
}

.mc_data_distance_inner_text {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 3%;
  width: 94%;
}

.mc_data_distance_inner_text span {
  margin-left: auto;
  font-size: 20px;
  color: white;
}

.reperation_submit_container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.reperation_submit_container button {
  background-color: #32394b;
  border-radius: 5px;
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #35b235;
}

.reperation_submit_container button:hover {
  background-color: #292e3d;
  cursor: pointer;
}

.main_inner .data_container:last-child {
  flex-direction: row;
  justify-content: left;
  margin-left: 15px;
}

.split_inner_container_split_history {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.history_card_container {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: auto;
}

.history_concrete_card_container img {
  width: auto;
  height: 100%;
  border-radius: 10px;
  object-position: center;
  object-fit: cover;
  max-width: 230px;
}

.history_card_img_placeholder {
  display: flex;
  justify-content: center;
  background-color: #32394b;
  align-items: center;
  height: 100%;
  aspect-ratio: 1;
  border-radius: 5px;
}

.history_concrete_card_data_container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
  display: flex;
}

.history_concrete_card_data_description {
  background-color: #25272f;
  margin-left: 10px;
  width: calc(100% - 10px);
  border-radius: 5px;
  color: white;
  margin-top: 5px;
  flex: 1;
  padding: 5px;
  overflow: auto;
}

.history_concrete_card_data_header {
  display: flex;
}

.history_concrete_card_data_header_data {
  flex: 1;
  margin-left: 10px;
  color: white;
}

.history_concrete_card_data_header_data:first-child {
  font-size: 20px;
}

.history_concrete_card_data_header_data p:last-child {
  font-size: 15px;
}

.history_concrete_card_data_header_date {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  margin-top: -10px;
}

.history_concrete_card_data_header_date svg {
  width: 20px;
  height: 20px;
}

.history_concrete_card_container {
  display: flex;
  background-color: #222534;
  width: 100%;
  min-height: 150px;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px;
  height: 150px;
}

.annotation_title_container {
  display: flex;
  flex-direction: column;
}

.annotation_title {
  display: flex;
  align-items: center;
}

#annotation_upload {
  display: flex;
  padding: 0px;
  border-radius: 5px;
  justify-content: center;
  overflow-y: hidden;
  height: 69px;
}

#uploadfix {
  width: 120px;
  margin-left: auto;
  display: flex;
  justify-content: center;
}

#annotation_upload label:hover img {
  filter: brightness(60%);
}

#annotation_upload label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 5px;
}

.history_search_container {
  display: flex;
}

.history_search_container .dropdown_container {
  width: 170px;
}

.data_container p:first-child {
  font-size: 25px;
}
.search_container p {
  font-size: 17px;
}

.right_basis_transport_actual {
  display: flex;
  align-items: center;
}

.right_basis_transport_actual p {
  display: inline;
}

.right_basis_transport {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right_basis_transport p {
  color: white;
  font-weight: bold;
}

.right_basis_transport_actual span {
  color: white;
  margin-right: 5px;
}

.right_basis_transport_actual span p {
  font-weight: normal;
}
.main_inner_top_interact_content {
  display: flex;
  justify-content: flex-start;
  position: relative;
  flex-direction: row;
}

.add_rep_container {
  display: flex;
}

.add_rep_right {
  margin-left: 20px;
}

.add_rep_header {
  color: white;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
}

.add_rep_desc {
  color: white;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 10px;
}

.add_rep_container .search_input {
  margin-top: 5px;
  padding-left: 10px;
  background-color: rgba(0, 0, 0, 0.25);
  font-family: Inter;
}

.add_rep_container .search_input:focus {
  background-color: rgba(0, 0, 0, 0.4);
}

.add_rep_left {
  display: flex;
  flex-direction: column;
}

.add_rep_price {
  display: flex;
  color: white;
}

.add_rep_price span {
  font-size: 15px;
  font-weight: bold;
}

.input-container {
  position: relative;
  width: 200px;
}
.input-container input {
  padding-right: 60px;
  width: 100%;
  box-sizing: border-box;
}
.input-suffix {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

#hoverfix button {
  background-color: rgba(0, 0, 0, 0.4);
}

#hoverfix button:hover {
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

#annotation_upload label:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.28);
}

#hoverfix input {
  margin-right: 0px;
  width: 45%;
  text-align: center;
  border-radius: 5px 0px 0px 5px;
}

.add_rep_price_interact button {
  background-color: #32394b;
  border: none;
  border-radius: 5px;
  color: white;
  min-width: 130px;
  min-height: 40px;
  position: relative;
  bottom: -24px;
}

.add_rep_price_interact button:hover {
  background-color: #292e3d;
  cursor: pointer;
}

.add_rep_price_number {
  margin-left: 10px;
  margin-right: 10px;
}

#hoverfix {
  width: 120px;
}

#hoverfix button {
  font-size: 20px;
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 0px;
  width: 27.5%;
}

.add_rep_invoice {
  margin-top: 15px;
  border-radius: 10px;
  background-color: #222534;
  list-style-type: none;
  display: flex;
  width: 544px;
  flex-direction: column;
}

.add_rep_invoice table {
  width: calc(100% - 15px);
  border-collapse: collapse;
  color: white;
  margin-left: 15px;
}

.add_rep_invoice th,
.add_rep_invoice td {
  text-align: left;
  padding: 8px;
  padding-left: 15px;
}

.add_rep_invoice tbody {
  display: block;
  height: 145px;
  overflow-y: auto;
  overflow-x: visible;
}

.mc_survey {
  position: relative;
}

.mc_acquisition {
  position: relative;
}

.edit_survey:hover {
  cursor: pointer;
  color: white;
}

.mc_data_sunken:hover {
  cursor: pointer;
}

.locafixhcard {
  display: inline;
  font-weight: bold;
}

#hcard_fix_prevloca {
  color: rgb(178, 53, 53);
}

.edit_survey {
  color: #a1a1a1;
}

.add_rep_invoice thead,
.add_rep_invoice tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.add_rep_invoice thead {
  width: calc(100%);
}

.add_rep_delete_item_invoice {
  color: red;
  margin-left: -10px;
  margin-right: 20px;
  z-index: 1000;
}

.add_rep_right {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.right_basis_item {
  display: flex;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: bold;
  margin-top: 5px;
}

.right_basis_item #hoverfix {
  margin-left: auto;
}

.add_rep_right_annotation p {
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.add_rep_right_basis {
  width: 400px;
  max-height: 140px;
  overflow-y: scroll;
}

.annotation_decription textarea {
  min-height: 75px;
  min-width: 100%;
  overflow-y: auto;
  font-size: 15px;
}

.add_rep_right_annotation,
.annotation_decription {
  margin-top: 10px;
}

.annotation_title_container {
  display: flex;
}

.right_basis_item .search_input {
  height: 30px;
  margin-top: 0px;
}

.add_rep_delete_item_invoice:hover {
  cursor: pointer;
}

.add_rep_invoice {
  min-height: 180px;
  height: 180px;
  overflow-y: hidden;
  overflow-x: visible;
}

.add_rep_invoice tr {
  position: relative;
  overflow: visible;
}

.add_rep_invoice tr::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(255, 255, 255, 0.5),
    rgba(0, 0, 0, 0)
  );
}

.add_rep_invoice table > tbody > tr:last-child::after {
  visibility: hidden;
}

.add_rep_invoice th:nth-child(2),
.add_rep_invoice td:nth-child(2) {
  width: 200px;
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  max-width: 200px;
}

.add_rep_invoice th:nth-child(3),
.add_rep_invoice td:nth-child(3) {
  width: 200px;
}

.add_rep_invoice th,
.add_rep_invoice td {
  position: relative;
}

.add_rep_invoice tr td:first-child {
  display: flex;
}

.add_rep_invoice th:first-child::after,
.add_rep_invoice td:first-child::after {
  display: none;
}

.add_rep_invoice td::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.5);
}

.add_rep_invoice tr:last-child td::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 1px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5),
    rgba(0, 0, 0, 0)
  );
}

.add_rep_invoice th::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 80%;
  width: 1px;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5),
    rgba(0, 0, 0, 0)
  );
}

#hoverfix button:last-child {
  border-radius: 0px 5px 5px 0px;
  position: relative;
}

#hoverfix button:last-child::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(255, 255, 255, 0.4),
    rgba(0, 0, 0, 0)
  );
  height: 80%;
  width: 1.8px;
  left: -0.9px;
  top: 10%;
}

.add_rep_right_annotation {
  display: flex;
  flex-direction: column;
}
