.search_container {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    height: 100%;
    gap:10px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.4);
}

.search_icon {
    position: absolute;
    left: 20px;
    z-index: 10;
    color: #ccc;
}

#modal_inner_fix {
    display: flex;
    flex-direction: column;
}

#modal_inner_fix>div {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

#modal_inner_fix>div>div {
    display: flex;
    flex-direction: column;
    min-width: 20vw;
    flex-grow: 1;
}

#modal_inner_fix .search_container {
    margin-left: 0px;
}

.add_mc_datepicker {
    display: flex;
    flex-direction: column;
    width: 100px;
}

#modal_inner_fix p {
    color: white;
    font-weight: bold;
    font-size: 15px;
}

.date_gps_sunken_container {
    display: flex;
}

#add_rep_mc_sunken {
    width: 40px;
    height: 40px;

}

#modal_inner_fix .dropdown_arrow {
    font-size: 15px;
    margin-bottom: 0;
}

#modal_inner_fix .add_mc_driven_dept .search_container {
    height: 80px;
}

.add_mc_driven_dept {
    width: 250px;
}

#modal_inner_fix .add_mc_dept .dropdown_container {
    width:120px;
}

.add_mc_driven, .add_mc_dept {
    margin-bottom:10px;
    margin-top:10px;
}

.add_mc_dept {
    margin-left: 20px;
}

.add_mc_date_km_dept .search_container {
    height: 70px;
}

.add_mc_dept .dropdown_container {
    width: 150px;
}

.add_mc_gps_sunken_sunkenstatus, .add_mc_gps_sunken_gpsstatus {
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add_mc_pic {
    height: 200px;
}

.add_mc_pic .search_container, .add_mc_pic label {
    height:100%;
}

#modal_inner_fix #annotation_upload {
    height: 100%;
}

.add_mc_gps_sunken_sunkenstatus {
    margin-top:25px;
}

#modal_inner_fix .react-datepicker-popper {
    z-index: 10000;
}

.add_mc_date_km_dept .search_container:first-child {
    margin-bottom: 10px;
}

#modal_inner_fix .add_mc_reg, #modal_inner_fix .add_mc_number{
    margin-bottom: 10px;
}

.add_mc_date_km_dept .search_container {
    width: 100%;
    justify-content: space-between;
}

#modal_inner_fix .button_date {
    margin-left: 0px;

}

.add_mc_number .search_container:hover, .add_mc_reg .search_container:hover, .add_afdeling .search_container:hover {
    background-color: rgba(0, 0, 0, 0.4);
    cursor:default;
}

.add_mc_reg, .add_mc_number, .add_afdeling {
    display: flex;
    flex-direction: column;
}

.add_afdeling {
    margin-bottom: 10px;
}

#modal_inner_fix input {
    padding-left: 5px;
    width:100%;
    margin-right:0px;
}

#modal_inner_fix span {
    color: white;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
}

.main_inner .search_container {
    margin-left: 10px;
}

.main_inner .search_container:first-child {
    margin-left: 0px;
}

.main_inner .search_container:first-child:hover {
    cursor: default;
    background-color: rgba(0, 0, 0, 0.4);
}
.search_container:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.58);
}
.search_container:hover .plus_icon {
    color: #dedede;
    background-color: rgb(203, 60, 60);
}

.search_input {
    font-size: 15px;
    flex-grow: 1;
    width: 100%;
    padding: 8px 10px;
    padding-left: 35px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.25);
    color: white;
    height: 40px;
    outline: none;
}

.search_input:focus {
    background-color: rgba(0, 0, 0, 0.35);
    border: none;
}

/* For Chrome, Safari, Edge, and Opera */
.search_input[type="number"]::-webkit-outer-spin-button,
.search_input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Firefox */
.search_input[type="number"] {
    appearance: none;
    -moz-appearance: textfield;
}




.main_inner_content_dashboard_cardcontainer {
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.main_inner_content_dashboard_cardcontainer_department {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}

.main_inner_content_dashboard_cardcontainer_department::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: linear-gradient(to right, transparent, white, transparent);
}

.main_inner_content_dashboard_cardcontainer:last-child .main_inner_content_dashboard_cardcontainer_department::after {
    display:none;
}

.main_inner_content_dashboard_cardcontainer .card {
    margin-right: 25px;
    margin-bottom: 25px;
}

.deptTitleDelete {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:10px;
    color: white;
    font-weight: 600;
    font-size: 32px;
    filter: drop-shadow(rgb(0,0,0,0.25) 0px 4px 4px);
}