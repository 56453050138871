.popup-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: linear-gradient(to top, #672431, #FB6059);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

.popup-actions input {
    flex-grow: 1;
    padding: 10px;
}

#butfix {
    background-color: #32394b;
    padding: 10px;
    cursor: pointer;
    border: 0px;
    height: 40px;
    border-radius: 5px;
    color: white;
    font-size: 15px;
}

#butfix:hover {
    background-color: #292e3d;
}

.popup-actions {
    display: flex;
    justify-content: space-between;
}

.popup-actions .search_input {
    width: fit-content;
}
