.tasks_card_container {
    width:722.75px;
}

.add_task_title p, .add_task_desc p {
    font-size: 15px;
    color: white;
    font-weight: bold;
}

.add_task_title {
    width:100%;
}

.add_task_title input, .add_task_desc input {
    padding-left: 10px;
    margin-top: 5px;
}

.add_task_desc p {
    margin-top: 10px;
}

.add_task_desc textarea {
    min-height: 125px;
    min-width: 100%;
    overflow-y: auto;
    font-size: 15px;
    margin-top: 5px;
    padding-left: 10px;
    background-color: rgba(0, 0, 0, 0.25);
    font-family: Inter;
}

.add_task_dds_container {
    display: flex;
}

.add_task_dds_container .dropdown_container {
    flex-grow: 1;
    margin-top: 10px;
}

.urgency .button_date {
    height: 40px;
}

.urgency {
    margin-top: 10px;

}

.urgency span {
    font-weight: bold;
    font-size: 15px;
    color: white;
}

.add_task_dds_container .dropdown_container:first-child {
    margin-right:20px;
}

.add_task_dds_container .dropdown_container:last-child {
    margin-left:20px;
}

