.main_inner_top {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 15px 10px;
}

.main_inner_top img {
    position:absolute;
    margin-right: 25px;
    right: 0px;
    height: 100px;
    width: auto;
    filter: drop-shadow(rgb(0,0,0,0.45) 4px 6px 4px);
}

.main_inner_top_interact {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex-grow: 1;
    padding: 20px 0;
}

.main_inner_top_interact_header {
    margin-bottom:8px;
}

.main_inner_top_interact_content {
    height: 60px;
}



