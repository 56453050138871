body {
    margin: 0;
    font-family: 'Arial', sans-serif;
}
.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #b23a48;
}

.login-form {
    color:white;
    padding: 20px 40px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
    width: 300px;
    box-sizing: border-box;
    background-color: #282930;
}

.login-form h2 {
    text-align: center;
    margin-bottom: 20px;
}

.login-form div {
    margin-bottom: 10px;
}

.login-form label {
    display: block;
    margin-bottom: 5px;
}

.login-form input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-form button {
    width: 100%;
    padding: 10px;
    background-color: #32394b;
    border: 10px;
    color: white;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-size: 16px;
}

.login-form button:hover {
    background-color: #292e3d;
}
