.split_inner_container_split {
    flex: 1;
    flex-direction: row;
    flex-grow: 1;
    height: 100%;
    position: relative;
}

.split_inner_container_split_mc {
    background-color:#222534;
    width: calc(100% - 15px);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.mc_data_location_gps_sunken {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    margin-left:20px;
}

.mc_data_location_gps_sunken:first-child {
    font-size: 23px;
    color: white;
}

.mc_data_status_location span:last-child {
    color: #35b235;
}

.mc_data_status_location p:last-child {
    font-size: 17px;
}

.mc_data_location_gps_sunken .gps_icon {
    margin-right: 20px;
    margin-left: 5px;
    margin-bottom: -5px;
}

.mc_data_location_gps_sunken .gps_icon:hover {
    background-color: #292e3d;
}

.mc_data_survey_acquisition {
    display: flex;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    color: white;
    font-size: 20px;
}

.mc_data_interact {
    display: flex;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-top:20px;
    margin-bottom: 20px;
    justify-content: space-between;
}

.mc_data_distance_inner {
    width:100%;
    height: auto;
}

.mc_data_distance {
    width: calc(100% - 40px);
    margin-left: 20px;
    background-color:#32394b;
    height:100px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mc_data_interact_status button {
    height: 50px;
    width: calc(100% - 10px);

    background-color: #32394b;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 15px;
}

.split_inner_container_split_mc_data button:hover {
    background-color:#292e3d;
}

.mc_data_interact_transfer {
    width:calc(50% - 20px);
}

.mc_transfer {
    display: flex;
}

.mc_transfer button {
    height: 50px;
    width: calc(100% - 10px);
    margin-left: auto;
    background-color: #32394b;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 15px;
}

.mc_data_interact_status {
    display: flex;
    justify-content: space-between;
    width: calc(50% - 20px);
}

.mc_data_interact_status div {
    width:50%;
}


.mc_data_distance p {
    color:white;
    font-size:20px;
    margin-bottom: 5px;
}

.mc_data_distance .progress_bar {
    top: 20px;
}

.mc_data_survey_acquisition p {
    margin-left: 20px;
    margin-bottom: 5px;
}

.mc_data_survey_acquisition div {
    padding-top: 20px;
    padding-bottom: 20px;
}
.mc_data_survey_acquisition div {
    background-color: white;
    border-radius: 5px;
    width: calc(50% - 20px);
    background-color: #32394b;
}

.mc_acquisition {
    margin-left: auto;
}

.mc_data_icons {
    display: flex;
    margin-left: auto;
}
.mc_data_sunken {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #32394b;
    border-radius: 50%;
    color: #35b235;
    font-size: 30px;
}

.mc_data_sunken:hover {
    background-color: #292e3d;
}

.split_inner_container_split_mc img {
    width:calc(100% - 40px);
    height: 400px;
    border-radius: 15px;
    margin:20px;
    cursor:pointer;
}

.split_inner_container_split_mc img:hover  {
    filter:brightness(75%);
}

.split_inner_container {
    display: flex;
    flex-direction: row;
    width:100%;
    height:100%;
}

.split_inner_container_split_history {
    width: calc(100% - 15px);
    margin-left: 15px;
    margin-bottom: 5px;
}

.split_inner_container_split:last-child {
    margin-right: 0;
}

.split_inner_container_split:last-child::after{
    display: none;
}

.split_inner_container_split::after {
    content: '';
    position: absolute;
    right: -2px;
    height: 90%;
    top: 5%;
    width: 2px;
    background: linear-gradient(to bottom, transparent, #ffffff, transparent);
    z-index: 1;
}