:root {
    --blue-color-grad: linear-gradient(to bottom, #1b1e21 0%, #313847 60%);
    --db: #282930;
}

.card {
    width: 300px;
    min-height: 100px;
    flex: 0 0 auto;
    background: #222534;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    filter: drop-shadow(rgb(0,0,0,0.45) 6px 8px 4px);
}

.card:hover {
    filter: drop-shadow(rgb(0,0,0,0.55) 8px 10px 4px);
    cursor: pointer;
}

.card svg {
    margin-left: auto;
    font-size: 25px;
}

.card_header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.card_header_container {
    display: flex;
    color: white;
    max-width: 240px;
}

.card_header_container_identifiers {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.card_header_container_identifiers>div>span {
    font-size: 16px;
}

.card_header_container_identifiers>div {
    display: flex;
    flex-direction: row;
}

.card_header_container_identifiers>div>p {
    word-break: break-word;
}

.card_header_container_identifiers>div>:first-child {
    font-weight: 600;
    min-width: 70px;
}

.card_header_container_identifiers>div>:last-child {
    width: fit-content;
}

.card_header_field_twoliner {
    flex-direction: column !important;
    gap: 2px;
}

.gps_sunken {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-left: auto;
}

.gps_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #333;
    background-color: #32394b;
    padding: 5px;
    margin-bottom:5px;
}

.gps_icon:hover {
    cursor:pointer;
}

.gps_sunken p {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #35b235;
    background-color: #383a44;
    font-size: 30px;
}

.card_status_icon img {
    height: 20px;
    margin-right:7px;
}

.progress_bar_container {
    width: 100%;
    background-color: #b23a48;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
    position: relative;
}

.progress_bar {
    height: 20px;
    transition: width 0.4s ease;
}

.progress_text {
    color: #000000;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.card_footer {
    margin-left: 10px;
    margin-right: 10px;
}

.card_image {
    margin-top: auto;
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    margin-left: 10px;
}

.card_image img {
    width: 200px;
    height: 120px;
    border-radius: 10px;
}

.card_number {
    font-size: 18px;
}

.card_id {
    font-size: 14px;
}
