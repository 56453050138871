:root {
    --main-bg-color: #B23A48;
}

.modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;

}

.modal_inner {
    padding: 20px;
    background: var(--main-bg-color);
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.modal_inner_header {
    color: white;
    font-size: 25px;
    font-weight: bold;
    position: relative;
    margin-bottom: 20px;
}

.modal_inner_header:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, transparent, #ffffff, transparent);
    z-index: 1;
}

.modal_inner_container {
    width: 100%;
}
