.admin_container {
    display: flex;
    width: 100%;
    gap: 3.5vw;
    color: white;
    padding: 0 10px;
}

.admin_container input {
    padding-left: 5px;
}

.admin_container>div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 85%;
}
.admin_container>div>div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.admin_container form {
    padding: 10px;
    border-radius: 5px;
    background-color: #0006;
    display: flex;
    flex-direction: column;
}

.admin_container form>div {
    display: flex;
    flex-direction: row;
}

.admin_container button {
    width: 60%;
}

.admin_container hr {
    width: 100%;
}

.admin_container ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #222534;
    border-radius: 5px;
    padding: 10px;
    max-height: 50vh;
    overflow-y: scroll;
}

.admin_container .trips_container {
    max-height: 65vh;
}

.admin_container ul>li {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.admin_container ul>li>div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.admin_container ul>li>div>div>* {
    float: right;
}

.admin_container ul>li>div>* {
    flex: 3;
}

.admin_container ul>li>div>:last-child {
    flex: 1 !important;
}

.admin_container ul>li>div:last-child {
    margin-right: 0px !important;
}