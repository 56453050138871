:root {
    --blue-color-grad: linear-gradient(to bottom, #1b1e21 0%, #313847 80%);
}

#navbar {
    height: 100vh;
    width: 17%;
    background: #282930;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#navbar_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    margin-left: 20px;
    width: 100%;
}

#navbar_content_header {
    font-size: 25px;
    font-weight: bold;
    color: white;
    margin-bottom: 20.5px;
    border-bottom: 2px solid white;
    padding-bottom: 10px;
}

#navbar_content_menu {
    width: calc(100% - 40px);
}

#navbar_content_menu li {
    display: flex;
    align-items: center;
    padding: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    height: 60px;
    transition: background-color 0.3s ease;
    border-radius: 4px;
    gap: 7px;
}

#navbar_content_menu li:hover {
    background-color: #3c3e48;
    color: white;
}

#navbar_content_menu p {
    margin: 0;
    font-size: 23px;
    color: white;
}

#navbar_bottom {
    width: 100%;
    background-color: #363841;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 15px;
}

#navbar_bottom>:first-child {
    padding-left: 5px;
}

#navbar_bottom>:last-child {
    padding-right: 5px;
}

#navbar_bottom_data {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 60%;
    justify-content: center;
}

#navbar_bottom_data_user p {
    color: white;
    font-size: 22px;
}

#navbar_bottom_data_user {
    display: flex;
    flex-direction: column;
}

#navbar_bottom_data_user p:last-child {
    font-size: 16px;
}

#navbar_bottom_data button {
    width: fit-content;
    background-color: #282930;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    padding: 6px 40px;
}

#navbar_bottom_data button:hover {
    background-color: #212228;
}
