#invoiceFix .add_rep_invoice {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    margin-top: 15px;
    width: 100%;
}

#invoiceFix .invoices_left .add_rep_invoice tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

#altinv {
    color: white;
    position: relative;
    top:25%;
}

#invoiceFix .add_rep_invoice table {
    display: flex;
    flex-direction: column;
    height: calc(100% - 15px);
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
    margin-left: 0px;
}

#invoiceFix .add_rep_invoice thead {
    flex-shrink: 0;
}

#invoiceFix .invoices_right .add_rep_invoice th:nth-child(4),
#invoiceFix .invoices_right .add_rep_invoice td:nth-child(4) {
    width: 200px;
}

#invoiceFix .add_rep_invoice tbody {
    flex-grow: 1;
    height: auto;
    overflow-y: auto;
    display: block;
}

#invoiceFix .add_rep_invoice tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

#invoiceFix .add_rep_invoice thead {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.invoices_left {
    height: calc(100% - 30px);
}

#invoiceFix .invoices_left .add_rep_invoice {
    margin-left: 15px;
    min-height: min-content;
    max-height: 100%;
}

#invoiceFix .invoices_left .add_rep_invoice {
    margin-left: 0px;
}

.invoices_header_end p, .invoices_header_start p {
    color: white;
    font-weight: bold;
    font-size: 14px;
    margin-right: 5px;
}

.invoices_header_end {
    margin-left: 10px;
}

.invoices_header_end, .invoices_header_start {
    display: flex;
    align-items: center;
}

.invoices_header_start {
    width: 150px;
    margin-right: 5px;
    margin-left: 4px;
}

.invoices_header_end {
    width: 150px;
    margin-right: 8px;
}

.invoices_right {
    margin-left: 15px;
    max-height: calc(100% - 30px);
    height:100%;
}

.invoices_right .add_rep_invoice {
    height: 100%;
}

.invoices_right .reffix {
    height:100%;
}

.invoices_right_top {
    display: flex;
    align-items: center;
}

.editIcon {
    color: #a1a1a1;
    margin-left: auto;
    order: 2;
    margin-top: 10px;
    margin-right: 35px;
}

.editIcon:hover {
    color: white;
    cursor: pointer;
}

.motorcycleReg {
    color:white;
    font-size: 20px;
    font-weight: 700;
    padding-top: 15px;
    padding-left: 15px;
}

#invoiceFix .invoices_right .add_rep_invoice table {
    margin-top: 15px;
}

#invoiceFix .invoices_right .add_rep_invoice {
    width: 100%;
    height: auto;
}
#invoiceFix .invoices_right {
    height: auto;
}

#invfix .data_container p:first-child {
    font-size:15px;
}


#invfix .data_container:first-child {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

#invfix .data_container:last-child {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    justify-content: space-between;
}

.invoice_current_header p, .invoice_current_footer p {
    margin-left: 10px;
}

.invoice_current_header, .invoice_current_footer {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    font-weight: bold;
}

#cssfix {
    width:fit-content;
}

#invoiceFix .invoices_right .add_rep_invoice {
    min-height:auto;
}

.invoices_left, .invoices_right {
    background-color: #222534;
    border-radius: 15px;
}

.invoices_left {
    width: calc(100% - 15px);
}

.accums_container {
    display:flex;
    flex-direction: row;
    gap: 20px;
}

.invoices_right_data {
    margin-top: 20px;
    display: flex;
    padding-bottom: 20px;
    position: relative;
    gap: 30px;
}

.invoices_right_data_buttons {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

.invoices_right_data_buttons button {
    height: 50px;
    width: 170px;
    color: white;
    font-weight: 700;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 15px;
    background-color: #32394b;
}

.invoices_right_data_buttons button:first-child {
    margin-bottom: 10px;
}

.invoices_right_data_buttons button:last-child {
    color: rgb(178, 53, 53);
}

.invoices_right_data_buttons button:hover {
    background-color: #292e3d;
}

.invoices_right_data_accums {
    display: flex;
    margin-left: auto;
    font-weight: bold;
    flex-direction: column;
    position: relative;
}

.invoices_accums_text {
    color: white;
    display: flex;
    flex-direction: column;
}

.invoices_accums_text span:last-child, .invoices_accums_actual span:last-child {
    font-weight: bold;
}

.invoices_accums_actual {
    color: white;
    display: flex;
    flex-direction: column;
}

.invoices_right_data {
    display: flex;

}

.invoices_accums_text span:last-child, .invoices_accums_actual span:last-child{
    margin-top: 31px;
}

#accums_fix {
    display:flex;
}

.date_input {
    font-size: 15px;
    flex-grow: 1;
    width: 100%;
    padding: 8px 10px;
    padding-left: 10px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.25);
    color: white;
    height: 40px;
    outline: none;
}